import c from "classnames";
import { Image } from "react-datocms";

import { Recipe } from "@/types/data";
import { transformContent } from "@/helpers/helperFunctions";
import { ModalVariants, SlapLabelVariants } from "@/types/layout";

import { RecipeBoxTag } from "./recipeBoxTag";
import Styles from "./recipeBoxModal.module.scss";
import { RecipeBoxBenefits } from "./recipeBoxBenefits";

import { Modal } from "../Modal";
import { Accordion } from "../FormElements/Accordion";
import { SlapLabel } from "../FormElements/SlapLabel";

type Props = {
	modalOpen: boolean;
	onModalClose: (event: any) => void;
	theModal: any;
	changeImage: (direction: string) => void;
	recipeData?: Recipe;
	imageShowing: number;
	benefits: string[];
	tag?: string;
	gender: string;
	name: string;
};

export const RecipeBoxModal = ({
	modalOpen,
	onModalClose,
	theModal,
	changeImage,
	recipeData,
	imageShowing,
	benefits,
	tag,
	gender,
	name,
}: Props) => (
	<Modal
		className={Styles.wrapper}
		modalOpen={modalOpen}
		onClose={onModalClose}
		thisRef={theModal}
		type={ModalVariants.info}
	>
		<div className={Styles.modalImage}>
			{recipeData?.images && recipeData.images.length > 1 && (
				<>
					<button
						type="button"
						className={Styles.modalImageLeft}
						onClick={() => changeImage("left")}
					></button>
					<button
						type="button"
						className={Styles.modalImageRight}
						onClick={() => changeImage("right")}
					></button>
				</>
			)}
			{recipeData?.images &&
				recipeData.images.map((image, i) => (
					<>
						<Image
							key={i}
							className={c({ [Styles.isShowing]: imageShowing === i })}
							data={image.responsiveImage}
						/>
						{tag && <RecipeBoxTag inModal tag={tag} />}
					</>
				))}
		</div>
		<h3>{`${recipeData?.title} ${recipeData?.subtitle}`}</h3>
		{benefits?.length > 0 && <RecipeBoxBenefits benefits={benefits} />}

		<p>{transformContent(recipeData?.description, gender, name)}</p>

		<div>
			{recipeData?.information.map((info, i) => (
				<div key={i}>
					<h5>{info.title}</h5>
					<p>{transformContent(info.description, gender, name)}</p>
				</div>
			))}
		</div>

		<Accordion
			heading={<h4 className={Styles.accordionHeading}>Ingredients</h4>}
			leftAlign
			inModal={theModal}
		>
			<p
				className={Styles.small}
				dangerouslySetInnerHTML={{
					__html: recipeData?.ingredientInformation || "",
				}}
			/>
		</Accordion>

		<Accordion
			heading={
				<h4 className={Styles.accordionHeading}>Nutritional Information</h4>
			}
			leftAlign
			inModal={theModal}
		>
			<div className={Styles.nutritionTable}>
				<div className={Styles.nutritionRow}>
					<div>Protein</div>
					<div>{recipeData?.proteinAmount}</div>
				</div>
				<div className={Styles.nutritionRow}>
					<div>Fat</div>
					<div>{recipeData?.fatAmount}</div>
				</div>
				<div className={Styles.nutritionRow}>
					<div>Crude Fibre</div>
					<div>{recipeData?.fibreAmount}</div>
				</div>
				<div className={Styles.nutritionRow}>
					<div>
						Ash{" "}
						<span data-tooltip="Ash in dog food is not the kind of ash you’d find after a fire but rather relates to the number of minerals that are in the dog food."></span>
					</div>
					<div>{recipeData?.ashAmount}</div>
				</div>
				{recipeData?.calciumAmount && (
					<div className={Styles.nutritionRow}>
						<div>Calcium</div>
						<div>{recipeData?.calciumAmount}</div>
					</div>
				)}
				{recipeData?.phosphorusAmount && (
					<div className={Styles.nutritionRow}>
						<div>Phosphorus</div>
						<div>{recipeData?.phosphorusAmount}</div>
					</div>
				)}
				<div className={Styles.nutritionRow}>
					<div>KCal Per 100g</div>
					<div>{recipeData?.kcalAmount}</div>
				</div>
			</div>
			<SlapLabel slim wrap variant={SlapLabelVariants.subtle}>
				When rehydrated (as fed), the fat content is{" "}
				{recipeData?.fatAmount &&
					(parseFloat(recipeData?.fatAmount) * 0.37).toFixed(1)}
				%
			</SlapLabel>
			{recipeData?.nutritionalAdditives && (
				<>
					<h5>Nutritional Additives (per kg)</h5>
					<p className={Styles.small}>{recipeData.nutritionalAdditives}</p>
				</>
			)}
		</Accordion>
	</Modal>
);
