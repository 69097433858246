/* eslint-disable indent */
import c from "classnames";
import { Image } from "react-datocms";

import { trim } from "@/helpers/helperFunctions";
import { ButtonVariants } from "@/types/inputFields";
import { track } from "@/helpers/analytics";
import { LocalProduct } from "@/types/Treats";

import Styles from "./recipeBox.module.scss";
import { RecipeBoxBenefits } from "./recipeBoxBenefits";
import { RecipeBoxTag } from "./recipeBoxTag";
import {
	RecipeBoxProps,
	SuitableRecipeBoxProps,
	UnsuitableRecipeBoxProps,
} from "./RecipeBox";

import { Button } from "../FormElements/Button";

type RecipeProps =
	| Omit<SuitableRecipeBoxProps, "setSelectedRecipes">
	| UnsuitableRecipeBoxProps;

type Props = RecipeProps &
	Omit<
		RecipeBoxProps,
		| "allRecipeData"
		| "position"
		| "breed"
		| "healthIssues"
		| "allergies"
		| "name"
		| "gender"
	> & {
		updateSelectedRecipes: () => void;
		setModalOpen: (open: boolean) => void;
		unsuitableReason?: string;
		recipeData: LocalProduct;
		link: boolean;
	};

export const RecipeBoxContent = ({
	recipe,
	updateSelectedRecipes,
	recipeData,
	tag,
	benefits,
	setModalOpen,
	unsuitable,
	unsuitableReason,
	selectedRecipes,
	large,
	type,
	link = false,
}: Props) => {
	return (
		<>
			<div
				className={c(Styles.image)}
				onClick={() => !link && updateSelectedRecipes()}
			>
				{recipeData && <Image data={recipeData.images[0].responsiveImage} />}

				{tag && <RecipeBoxTag tag={tag} />}
			</div>

			<div className={Styles.content}>
				<h2>{recipeData?.title}</h2>

				<h4 className={Styles.subtitle}>{recipeData?.subtitle}</h4>

				{!unsuitable && benefits && benefits.length > 0 && (
					<RecipeBoxBenefits benefits={benefits} />
				)}

				{!unsuitable && large && (
					<p
						dangerouslySetInnerHTML={{
							__html: trim(recipeData?.description, 85, "words"),
						}}
					/>
				)}

				{link && (
					<>
						<br />

						<Button
							variant={ButtonVariants.default}
							url={"/recipes/" + recipeData?.slug}
							width="relative"
							className={Styles.pageButton}
							left
						>
							More info
						</Button>
					</>
				)}

				{!unsuitable && !link && (
					<Button
						variant={ButtonVariants.ghost}
						className={Styles.detailsButton}
						rightIcon={{ icon: "ForwardArrow",
							width: "24px",
							height: "24px" }}
						width="relative"
						left
						onClick={() => {
							setModalOpen(true);
							// TRACKING
							track("Open Recipe Modal", {
								category: "modal",
								type: "recipe",
								title: recipeData?.title,
								location: type,
							});
						}}
					>
						Recipe details
					</Button>
				)}

				{unsuitableReason && (
					<div className={Styles.unsuitableReason}>
						Unsuitable for {unsuitableReason}
					</div>
				)}

				{!unsuitable && !link && (
					<Button
						variant={ButtonVariants.clear}
						className={c(Styles.addButton)}
						onClick={() => updateSelectedRecipes()}
					>
						{selectedRecipes?.includes(recipe)
							? "Added"
							: "Add recipe"}
					</Button>
				)}
			</div>
		</>
	);
};
