import c from "classnames";

import { Recipe, UnsuitableRecipeOverview } from "@/types/data";

import Styles from "./recipeBox.module.scss";
import { RecipeBoxContent } from "./RecipeBoxContent";
import { RecipeBoxModal } from "./RecipeBoxModal";
import { useRecipeBox } from "./useRecipeBox";

export type SuitableRecipeBoxProps = {
	unsuitableRecipe?: never;
	recipe: string;
	unsuitable?: false;
	benefits?: string[];
	tag?: string;
	setSelectedRecipes?: (recipes: Recipe) => void;
};
export type UnsuitableRecipeBoxProps = {
	unsuitableRecipe: UnsuitableRecipeOverview;
	recipe?: never;
	unsuitable: true;
	benefits?: never;
	tag?: never;
	setSelectedRecipes?: never;
};

export type RecipeBoxProps = {
	allRecipeData: Recipe[];
	selectedRecipes?: string[];
	large?: boolean;
	type?: string;
	number?: number;
	position?: Record<string, unknown>;
	isDisabled?: boolean;
	breed?: string;
	allergies?: string[];
	healthIssues?: string[];
	gender?: string;
	name?: string;
	border?: boolean;
	link?: boolean;
};

export type RecipeBoxPropsUnion = (
	| SuitableRecipeBoxProps
	| UnsuitableRecipeBoxProps
) &
	RecipeBoxProps;

export const RecipeBox = (props: RecipeBoxPropsUnion) => {
	const {
		recipe,
		selectedRecipes,
		position,
		isDisabled,
		unsuitable,
		gender,
		name,
		border,
		link = false,
	} = props;

	const {
		recipeBoxContentProps,
		modalOpen,
		theModal,
		changeImage,
		recipeData,
		imageShowing,
		benefits,
		tag,
		onModalClose,
		updateSelectedRecipes,
		setModalOpen,
		unsuitableReason,
		large,
		type,
	} = useRecipeBox(props);

	if (isDisabled) {
		return null;
	}

	return (
		<>
			<div
				className={c(Styles.recipeBox, {
					[Styles.selected]: !unsuitable && selectedRecipes?.includes(recipe),
					[Styles.unsuitable]: unsuitable,
					[Styles.border]: border,
				})}
				style={position}
			>
				<RecipeBoxContent
					updateSelectedRecipes={updateSelectedRecipes}
					recipeData={recipeData}
					setModalOpen={setModalOpen}
					unsuitableReason={unsuitableReason}
					selectedRecipes={selectedRecipes}
					large={large}
					type={type}
					link={link}
					{...recipeBoxContentProps}
				/>
			</div>

			{!link && gender && name && (
				<RecipeBoxModal
					modalOpen={modalOpen}
					theModal={theModal}
					changeImage={changeImage}
					recipeData={recipeData}
					imageShowing={imageShowing}
					benefits={benefits}
					tag={tag}
					onModalClose={onModalClose}
					gender={gender}
					name={name}
				/>
			)}
		</>
	);
};
